import React, { useState, useEffect } from 'react';
import './App.css';
import SimpleImageSlider from 'react-simple-image-slider';

function App() {
  const slider = React.useRef();
  const images = [
    { url: 'https://firebasestorage.googleapis.com/v0/b/reonhairlanding.appspot.com/o/%E1%84%86%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB2.png?alt=media&token=2455966d-0a0e-4f26-b34c-d6e641a34085' },
    { url: 'https://firebasestorage.googleapis.com/v0/b/reonhairlanding.appspot.com/o/%E1%84%89%E1%85%A3%E1%86%B7%E1%84%91%E1%85%B3%E1%84%89%E1%85%B5%E1%86%AF2.png?alt=media&token=013261d6-3d51-4313-bc49-9e7480a974fd' },
    { url: 'https://firebasestorage.googleapis.com/v0/b/reonhairlanding.appspot.com/o/%E1%84%86%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB5.png?alt=media&token=63365ab9-944d-4027-97c8-9d99af30aa1b' }
  ];

  useEffect(() => {
    const t = setInterval(() => {

    }, 1000);
    return () => {
      clearInterval(t);
    }
  }, [])

  return (
    <>
    <div className='b'>
      {/* <img
        src='https://firebasestorage.googleapis.com/v0/b/reonhairlanding.appspot.com/o/background.svg?alt=media&token=cbe6cb25-2730-43d2-9853-83f11a31d1c2'
        alt='background'
        style={{position: 'fixed', zIndex: 0}} /> */}
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <div style={{flex: '1', flexDirection: 'row'}}>
          <text style={{color: '#FCCBBB', marginRight: '20px'}}>ACADEMY</text>
          <text style={{color: '#FCCBBB', marginRight: '20px'}}>SALON</text>
          <text style={{color: '#FCCBBB'}}>FRANCHISE</text>
        </div>
        <div style={{display: 'flex', flex: '1', justifyContent: 'center'}}>
          <img
            src='https://firebasestorage.googleapis.com/v0/b/reonhairlanding.appspot.com/o/reonhair_logo.svg?alt=media&token=f75091e2-4ec8-42fc-98a5-80bf9c22f054'
            alt='reonhair_logo'
            style={{width: '100px'}} />
        </div>
        <div style={{display: 'flex', flex: '1', alignItems: 'flex-start', justifyContent: 'flex-end'}}>
          <img
            src='https://firebasestorage.googleapis.com/v0/b/reonhairlanding.appspot.com/o/facebook_logo.svg?alt=media&token=96f827a6-6f58-4264-999f-2c56633c8382'
            alt='facebook' />
          <div style={{marginRight: '30px'}} />
          <img
            src='https://firebasestorage.googleapis.com/v0/b/reonhairlanding.appspot.com/o/instagram_logo.svg?alt=media&token=048a03fd-9204-4f7d-ad99-d0e6ffa749b0'
            alt='instagram' />
        </div>
      </div>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center',marginTop: '200px', marginBottom: '200px'}}>
        <img
          src='https://firebasestorage.googleapis.com/v0/b/reonhairlanding.appspot.com/o/main_title.png?alt=media&token=f93cdf0a-b489-4c02-8845-5516bcb254b9'
          alt='title'
          style={{
            alignSelf: 'center',
            width: window.innerWidth-800
          }} />
      </div>
      <img
        src='https://firebasestorage.googleapis.com/v0/b/reonhairlanding.appspot.com/o/REONHAIR%20INC..svg?alt=media&token=081ceee3-97d5-47f6-b350-73b904f62597'
        alt='REONHAIR Inc.'
        style={{
          width: '15px',
          position: 'relative',
        }} />
    </div>
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center',backgroundColor: '#5A4C43', padding: '50px'}}>
      <div>
        <text style={{color: '#FCCBBB', fontSize: '40px', fontWeight: 'bold'}}>STORY</text>
      </div>
      <div style={{marginTop: '20px'}} />
      <div style={{width: '100px', height: '5px', backgroundColor: '#FCCBBB'}} />
      <div style={{marginTop: '50px'}} />
      <img
        src='https://firebasestorage.googleapis.com/v0/b/reonhairlanding.appspot.com/o/aboutusimage.png?alt=media&token=dbdb664f-a2ea-4081-ad29-25662daa2faf'
        alt='About us'
        style={{paddingLeft: '50px', width: '90%', height: '100%'}}
         />
      {/* <div style={{marginBottom: '50px'}}>
        <text style={{color: '#FCCBBB', fontSize: '30px'}}>ABOUT US</text>
      </div>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <img
          src='https://firebasestorage.googleapis.com/v0/b/reonhairlanding.appspot.com/o/reon%20%E1%84%80%E1%85%A1%E1%84%8B%E1%85%B12.png?alt=media&token=9544c49f-3c63-4d37-abe0-299e4f6836fa'
          alt='가위'
          style={{width: '200px', height: '200px'}} />
        <div style={{marginRight: '30px'}} />
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <text style={{color: '#FCCBBB', fontSize: '35px', marginBottom: '20px'}}>Refined</text>
          <text style={{color: '#FCCBBB', fontSize: '35px', marginBottom: '20px'}}>Education</text>
          <text style={{color: '#FCCBBB', fontSize: '35px', marginBottom: '20px'}}>Organization</text>
          <text style={{color: '#FCCBBB', fontSize: '35px'}}>Notable</text>
        </div>
        <div style={{marginRight: '30px'}} />
        <img
         src='https://firebasestorage.googleapis.com/v0/b/reonhairlanding.appspot.com/o/SINCE1995.png?alt=media&token=5235fbd9-e90c-4762-a5e6-e5db345802d7'
         alt='since 1995'
         style={{width: '250px', height: '250px'}} />
      </div> */}
      <div style={{marginTop: '100px'}} />
    </div>
    <div className='b2'>
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '50px'}}>
      <div>
        <text style={{color: '#FCCBBB', fontSize: '40px', fontWeight: 'bold'}}>ACADEMY</text>
      </div>
      <div style={{marginTop: '20px'}} />
      <div style={{width: '100px', height: '5px', backgroundColor: '#FCCBBB'}} />
      <div style={{marginTop: '100px'}} />
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <img
            src='https://firebasestorage.googleapis.com/v0/b/reonhairlanding.appspot.com/o/Mask%20Group%201.png?alt=media&token=95e7c37a-6584-4f42-8ece-09edd4db20ba'
            alt='ACADEMY'
            style={{width: '200px', height: '200px', borderRadius: '100px', marginBottom: '20px'}} />
          <text style={{color: '#FCCBBB', fontSize: '30px'}}>ACADEMY</text>
        </div>
        <div style={{marginRight: '100px'}} />
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <img
            src='https://firebasestorage.googleapis.com/v0/b/reonhairlanding.appspot.com/o/Mask%20Group%202.png?alt=media&token=be243891-4112-44c1-a451-5beba2b1de6f'
            alt='ACADEMY'
            style={{width: '200px', height: '200px', borderRadius: '100px', marginBottom: '20px'}} />
          <text style={{color: '#FCCBBB', fontSize: '30px'}}>SALON</text>
        </div>
        <div style={{marginRight: '100px'}} />
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <img
            src='https://firebasestorage.googleapis.com/v0/b/reonhairlanding.appspot.com/o/Mask%20Group%203.png?alt=media&token=48ebaecc-b236-454d-a4f3-af45506697a1'
            alt='ACADEMY'
            style={{width: '200px', height: '200px', borderRadius: '100px', marginBottom: '20px'}} />
          <text style={{color: '#FCCBBB', fontSize: '30px'}}>FRANCHISE</text>
        </div>
      </div>
    </div>
    <div style={{marginTop: '100px'}} />
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '50px'}}>
      <div>
        <text style={{color: '#FCCBBB', fontSize: '40px', fontWeight: 'bold'}}>STORE VIEW</text>
      </div>
      <div style={{marginTop: '20px'}} />
      <div style={{width: '100px', height: '5px', backgroundColor: '#FCCBBB'}} />
      <div style={{marginTop: '50px'}} />
      <SimpleImageSlider
        ref={slider}
        showBullets={true}
        width={window.innerWidth-300}
        height={(window.innerWidth-300)/2}
        showNavs={true}
        images={images} />
    </div>
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', backgroundColor: '#5A4C43', padding: '50px'}}>
      <img
        src='https://firebasestorage.googleapis.com/v0/b/reonhairlanding.appspot.com/o/app_store.png?alt=media&token=2d816a23-dc6d-438d-8686-14cb37692314'
        alt='appstore'
        style={{width: '200px'}} />
      <img
        src='https://firebasestorage.googleapis.com/v0/b/reonhairlanding.appspot.com/o/play_store.png?alt=media&token=957e4664-529d-49c3-b7ca-9becbe6d6cbd'
        alt='playstore'
        style={{width: '200px'}} />
    </div>
    </div>
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center',backgroundColor: '#5A4C43', padding: '50px'}}>
      <div style={{marginTop: '50px'}} />
      <div style={{width: '90%', display: 'flex', flexDirection: 'row'}}>
        <div style={{flex: '2', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <text style={{color: '#FCCBBB', fontSize: '20px', marginBottom: '20px', textDecorationLine: 'underline'}}>Address</text>
          <text style={{color: '#FCCBBB', marginBottom: '10px'}}>51 Đường 16, phường An Phú, Quận 2, Thành phố Hồ Chí Minh</text>
          <text style={{color: '#FCCBBB', marginBottom: '10px'}}>51 street 16, An Phu ward, District 2, Ho Chi Minh city</text>
        </div>
        <div style={{flex: '1'}} />
        <div style={{flex: '2', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <text style={{color: '#FCCBBB', fontSize: '20px', marginBottom: '20px', textDecorationLine: 'underline'}}>Phone</text>
          <text style={{color: '#FCCBBB', marginBottom: '10px'}}>+84 93 250 6353</text>
        </div>
        <div style={{flex: '1'}} />
        <div style={{flex: '2', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <text style={{color: '#FCCBBB', fontSize: '20px', marginBottom: '20px', textDecorationLine: 'underline'}}>E-Mail</text>
          <text style={{color: '#FCCBBB', marginBottom: '10px'}}>reonhair1995@gmail.com</text>
        </div>
      </div>
      <div style={{marginTop: '50px'}} />
    </div>
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center',backgroundColor: '#5A4C43', padding: '50px'}}>
      <text style={{color: '#FCCBBB'}}>Copyright © 2020 REONHAIR INC. All rights reserved</text>
    </div>
    </>
  );
}

export default App;
